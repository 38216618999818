import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import { hotjar } from 'react-hotjar'
import Geocode from 'react-geocode'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faKey, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { GOOGLE_MAP_API_KEY } from './constants/Constants'
import {
  GROUP,
  INDEX,
  LOGIN,
  OVERVIEW,
  SETTINGS_EDIT_MAMORIO, SETTINGS_GROUP_MANAGEMENT, SETTINGS_MAMORIO_SPOT,
  SETTINGS_GROUP_MANAGEMENT_USERS,
  UNAUTHORIZED,
  USER_SETTING,
  VERIFICATION,
  NEWS,
  BIZ_PLUS_TEST,
  MAMORIOS_DETAIL,
  HEATMAP,
} from './constants/Paths'
import RequireAuth from './components/auth/RequireAuth'
import Login from './containers/Login/Login'
import Verification from './containers/Verification'
import Unauthorized from './containers/Unauthorized'
import Overview from './containers/Overview'
import User from './containers/User'
import SettingsEditMamorio from './components/settings/mamorio/SettingsEditMamorio'
import SettingsGroupManagement from './components/settings/group/SettingsGroupManagement'
import SettingsGroupManagementUsers from './components/settings/group/SettingsGroupManagement/SettingsGroupManagementUsers'
import SettingsMamorioSpot from './components/settings/SettingsMamorioSpot'
import RootModal from './components/common/RootModal'
import NewsPage from './components/news/news'

import type { RootState } from './store'
import HeatmapPage from './components/heatmap/heatmap'
import BizPlustTestPage from './components/biz_plust_test/biz_plust_test'
import AntiStealPage from './components/anti_steal/anti_steal'
import MamorioDetailPage from './components/overview/MamorioDetailPage'

library.add(faKey, faMobileAlt)

export default () => {
  const currentUser = useSelector((state: RootState) => state.user.user)
  const team = useSelector((state: RootState) => state.team)
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    if (isInitialized) return

    ReactGA.initialize('UA-33978950-20')
    ReactGA.pageview(window.location.pathname)
    hotjar.initialize(862615, 6)
    Geocode.setApiKey(GOOGLE_MAP_API_KEY)

    setIsInitialized(true)
  }, [isInitialized])

  return (
    <React.StrictMode>
      <BrowserRouter>
        <Switch>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route path={VERIFICATION}>
            <Verification />
          </Route>
          <Route path={UNAUTHORIZED}>
            <Unauthorized />
          </Route>
          <Route
            exact
            path={INDEX}
            render={() => (
              <RequireAuth>
                <SettingsEditMamorio showTabs={false} showEditButton={false} />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path={OVERVIEW}
            render={() => (
              <RequireAuth>
                {
                  team.id === 566 || team.id === 567
                    ? <HeatmapPage />
                    : <SettingsEditMamorio showTabs={false} showEditButton={false} />
                }
              </RequireAuth>
            )}
          />
          <Route
            path={USER_SETTING}
            render={() => (
              <RequireAuth>
                <User />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path={GROUP}
            render={() => (
              <RequireAuth>
                {
                  team.id === 566 || team.id === 567
                    ? <HeatmapPage />
                    : <Overview />
                }
              </RequireAuth>
            )}
          />
          <Route
            exact
            path={NEWS}
            render={() => (
              <RequireAuth>
                <NewsPage />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path={HEATMAP}
            render={() => (
              <RequireAuth>
                <HeatmapPage />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path="/anti_steal"
            render={() => (
              <RequireAuth>
                <AntiStealPage />
              </RequireAuth>
            )}
          />
          <Route
            exact
            path={BIZ_PLUS_TEST}
            render={() => (
              <BizPlustTestPage />
            )}
          />
          {
            currentUser && (
              <>
                <Route
                  path={SETTINGS_EDIT_MAMORIO}
                  render={() => (
                    <RequireAuth>
                      <SettingsEditMamorio />
                    </RequireAuth>
                  )}
                />
                <Route
                  path={SETTINGS_GROUP_MANAGEMENT_USERS}
                  render={() => (
                    <RequireAuth>
                      <SettingsGroupManagementUsers />
                    </RequireAuth>
                  )}
                />
                <Route
                  exact
                  path={SETTINGS_GROUP_MANAGEMENT}
                  render={() => (
                    <RequireAuth>
                      <SettingsGroupManagement />
                    </RequireAuth>
                  )}
                />
                <Route
                  path={MAMORIOS_DETAIL}
                  render={() => (
                    <MamorioDetailPage />
                  )}
                />
                <Route
                  path={SETTINGS_MAMORIO_SPOT}
                  render={() => (
                    <RequireAuth>
                      <SettingsMamorioSpot />
                    </RequireAuth>
                  )}
                />
              </>
            )
          }
        </Switch>
      </BrowserRouter>
      <RootModal />
    </React.StrictMode>
  )
}
